import {action} from 'easy-peasy';
import * as Actions from './actions'

const shopModel = {
    merchants:[],
    categories:[],
    loading:false,
    error:null,
    setData:action((state,payload) => {
        if(payload.key === 'merchants' && payload.append === true){
            state.merchants = [...state.merchants,...payload.value];
        }else{
            state[payload.key] = payload.value;
        }
    }),
    resetMerchants:action((state,payload) => {
        state.merchants = [];
    }),
    getShops:Actions.getShops,
    fetchCategories: Actions.fetchCategories,
    confirmEmail: Actions.confirmEmail,
};

export default shopModel;
