import React from 'react';
import {createRoot} from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux';
import {AxiosInterceptor} from "./services/axios";
import 'bootstrap-css-only/css/bootstrap.min.css';
import './assets/css/hamburgers.css';
import './assets/sass/screen.scss';
import 'animate.css/animate.min.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AxiosInterceptor><StoreProvider store={store}><App/></StoreProvider></AxiosInterceptor>);
serviceWorker.unregister();
