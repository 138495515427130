import {action} from 'easy-peasy';
import * as Actions from './actions'

const userModel = {
    loginModalAction:false,
    user:null,
    showCart:false,
    error:null,
    setData:action((state,payload) => {
        state[payload.key] = payload.value;
    }),
    getUserBasket:Actions.getBasket
};

export default userModel;
