import {action, thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";
import region from "../../../services/regionService";
import _ from 'lodash';

export const getMerchant= thunk(async (actions, payload) => {
    try{
        const {slug} = payload;
        const data = {
            filter: {
                slug,
            },
        };
        actions.setData({key:'loading',value:true});
        axiosInstance.post('/merchants/search', data)
            .then(({data})=>{
                if(Array.isArray(data) && data.length > 0){
                    actions.setData({key:'merchant', value:data[0]});
                }
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                actions.setData({key:'error', value:err});
            })
            .finally(()=>{
                actions.setData({key:'loading',value:false});
            })
    }catch (e) {
        console.log(e);
    }
});
export const getSimilarMerchants= thunk(async (actions, payload) => {
    try {
        const {merchantId, category} = payload;
        const data = {
            filter: {
                country: region.code,
                publish: true,
                publishOnWeb: true,
                withoutMerchant: merchantId,
            },
        };
        if (category) {
            data.filter.category = category;
        } else {
            data.filter.withPriority = true;
        }

        actions.setData({key:'loadingSimilarMerchants',value:true});
        axiosInstance.post('/merchants/search', data)
            .then(({data})=>{
                actions.setData({key:'similarShops', value:_(data).shuffle().value()});
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                actions.setData({key:'error', value:err});
            })
            .finally(()=>{
                actions.setData({key:'loadingSimilarMerchants',value:false});
            })
    }catch (e) {
        console.log(e);
    }
});
