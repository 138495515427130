import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
import { useNavigate } from 'react-router-dom';
import '../../../assets/sass/_btn-free-trial.scss';

const FreeTrialButton = ({ classes, text }) => {
  const navigate = useNavigate();

  const onRegisterHandler = (e) => {
    e.preventDefault();
    navigate('/register');
  };

  return (
    <a
      className={classes}
      onClick={onRegisterHandler}
      style={{ whiteSpace: 'nowrap', marginRight: 0 }}>
      {text || <FormattedMessage id="FREE_TRIAL" />}
    </a>
  );
};
FreeTrialButton.propTypes = {
  classes: PropType.string,
  text: PropType.any,
};
export default memo(FreeTrialButton);
