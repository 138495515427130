import {thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";
export const getShops= thunk(async (actions, payload) => {
    try {
        const {currentPage,pageSize, itemsPerPage, category, isWebShop, withPriority, queryCompany, append = true} = payload;
        const data = {
            filter: {
                publish: true,
                publishOnWeb: true,
                withImages: true,
                sortPriorityCompanyName: true,
            },
            paginationByPageNumbers: {
                page: currentPage,
                elements: pageSize,
            },
        };

        if (category !== '' && !isWebShop) {
            data.filter.category = category;
        }

        if (isWebShop) {
            data.filter.isWebShop = true;
        }

        if (withPriority) {
            data.filter.priority = true;
        }

        if (queryCompany !== '') {
            data.filter.queryCompany = queryCompany;
        }
        actions.setData({key:'loading',value:true});
        axiosInstance.post('/merchants/search-plus-pagination-params', data)
            .then(({data})=>{
                const {merchants} = data;
                actions.setData({key:'merchants', value:merchants, append});
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                console.log('error:', err);
                actions.setData({key:'error',value:err});
            })
            .finally(()=>{
                actions.setData({key:'loading',value:false});
            });
    }catch (e) {
        console.log(e);
    }
});

export const fetchCategories= thunk(async (actions, payload) => {
    try {
        actions.setData({key:'loading',value:true});
        axiosInstance.post('/categories/search', {
            filter: {
                region: 'ES',
                published: true,
                sortByOrder: true,
            },
        })
            .then(({data})=>{
                actions.setData({key:'categories', value:data});
            })
            .catch(err=>{
                console.log('error:', err);
                actions.setData({key:'error',value:err});
            })
            .finally(()=>{actions.setData({key:'loading',value:false});});
    }catch (e) {
        console.log(e);
    }
});
export const confirmEmail= thunk(async (actions, payload) => {
    try {
        const {email, code} = payload;
        actions.setData({key:'loading',value:true});
        axiosInstance.post('/users/confirm_register_by_email', {
            email, code
        })
            .then(({data})=>{
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                console.log('error:', err);
                actions.setData({key:'error',value:err});
            })
            .finally(()=>{actions.setData({key:'loading',value:false});});
    }catch (e) {
        console.log(e);
    }
});
