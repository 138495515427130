import {action, thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";

export const getProducts= thunk(async (actions, payload) => {
    try{
        const {merchantId} = payload;
        const data = {
            filter: {
                fromMerchant: merchantId,
                sortByCreatedDesc: true,
            },
        };
        actions.setData({key:'loadingProducts',value:true});
        axiosInstance.post('/products/search', data)
            .then(({data})=>{
                actions.setData({key:'products', value:data});
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                actions.setData({key:'error', value:err});
            })
            .finally(()=>{
                actions.setData({key:'loadingProducts',value:false});
            })
    }catch (e) {
        console.log(e);
    }
});

export const getProductByUid= thunk(async (actions, payload) => {
    try{
        const {uid} = payload;
        actions.setData({key:'loadingProduct',value:true});
        axiosInstance.get(`/products/${uid}`)
            .then(({data})=>{
                actions.setData({key:'product', value:data});
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                actions.setData({key:'error', value:err});
            })
            .finally(()=>{
                actions.setData({key:'loadingProduct',value:false});
            })
    }catch (e) {
        console.log(e);
    }
});
