import {action} from 'easy-peasy';
import * as Actions from './actions'

const contactFormModel = {
    messageSent:false,
    sendingError:null,
    setData:action((state,payload) => {
        state[payload.key] = payload.value;
    }),
    sendMail:Actions.sendMail,
};
export default contactFormModel;
