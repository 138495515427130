import { useEffect } from 'react'
import axios from 'axios'
//import {API_URL} from "../../commons/configs";
const API_URL = 'https://api2.goyuge.com';
// Create an instance of Axios with a base URL
const axiosInstance = axios.create({
    baseURL: API_URL
})

export const AxiosInterceptor = ({ children }) => {
    const router = {}

    useEffect(() => {
        const resInterceptor = response => {
            return response
        }

        const errInterceptor = error => {
            console.log('errInterceptor:', error)
            if (error.response.status === 401 || error.response.status === 403) {
                router.push('/401')
            }

            return Promise.reject(error)
        }

        const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor)

        return () => axiosInstance.interceptors.response.eject(interceptor)
    }, [router])

    return children
}

export const setAxiosToken = token => {
    if (token) {
        axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else axiosInstance.defaults.headers.common['Authorization'] = ''
}

export const setAxiosHeader = (header, value) => axios.defaults.headers.post['Content-Type'] = 'application/json';

// Export the Axios instance
export default axiosInstance
