import {action} from 'easy-peasy';
import * as Actions from './actions'

const registerModel = {
    loading:false,
    emailSent:false,
    errorTrialMessage:null,
    setData:action((state,payload) => {
            state[payload.key] = payload.value;
    }),
    createTrialAccount: Actions.createTrialAccount,
    setEmailSent: Actions.setEmailSent,
    setErrorTrialMessage: Actions.setErrorTrialMessage,
};

export default registerModel;
