import React, {
  useState,
  useCallback,
  Suspense,
  useEffect,
} from 'react';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter,
  Routes,
  Route, Navigate,useLocation
} from 'react-router-dom'
import language from './services/language/languageService';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {NavBar, Shop, Merchant,AppInfo, Apps, Register, NotFound,Pricing, Footer,ProductPage,ContactForm ,Technology} from './features';
import './assets/sass/screen.scss';
import {useStoreActions} from "easy-peasy";

const App = () => {

  const [locale, setLocale] = useState(language.defaultLocale);
  const [drawerState, setDrawerState] = useState('close');
  const [logoKey, setLogoKey] = useState(1);
  const [messages, setMessages] = useState(
      language.getMessages(language.defaultLocale),
  );
    const {setData} = useStoreActions((actions) => actions.user);

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        const username = localStorage.getItem('username');
        setData({user: {access_token: accessToken, username} });
    }, []);


  const changeLanguage = useCallback(newLocale => {
        const msg = language.getMessages(newLocale);
        setLocale(newLocale);
        setMessages(msg);
      }, [locale, messages]);

    const handleOnClickEvent = useCallback(() => {
        setLogoKey(current => (current  === 1 ? 2 : 1));
    }, []);

    const handleLanguageChange = useCallback((_locale) => {
            changeLanguage(_locale);
        }, [changeLanguage]);

  return ( <div className="overflow-x-hidden overflow-y-hidden"
      style={{ backgroundColor: '#f8f9fa'}}
      >
        <IntlProvider key={locale} locale={locale} messages={messages}>
            <BrowserRouter>
                    <ToastContainer />
                        <NavBar
                        selectedLanguage={locale}
                        onLanguageChange={changeLanguage}
                        onDrawerStateChange={setDrawerState}
                        logokey={logoKey}
                    />
                  <Suspense fallback={() => <div>'loading'</div>}>
                      <Routes>
                          <Route path="/pricing" element={<Pricing/>} />
                          <Route path="/register" element={<Register />} />
                          <Route path="/apps" element={ <Apps />}/>
                          <Route path="/app" element={<AppInfo />}/>
                          <Route path="/not-found" element={<NotFound />} />
                          <Route path="/contact" element={
                              <GoogleReCaptchaProvider
                                  reCaptchaKey={'6LciwP8kAAAAAB1Ixz9MkAhjbO-3KwItHE141I6R'}>
                                  <ContactForm selectedLanguage={locale}/>
                              </GoogleReCaptchaProvider>}
                          />
                          <Route path="/shops/:slug" element={<Merchant drawerState={drawerState}/>}/>
                          <Route path="/shops"
                                 element={<Shop
                                     selectedLanguage={locale}
                                     onClickEvent={handleOnClickEvent}
                                 />}/>
                          <Route path="/product/:productId"
                                 element={
                                     <ProductPage selectedLanguage={locale}/>
                                 }/>

                    <Route path="/" element={<Technology locale={locale} onClickEvent={handleOnClickEvent}/>}/>
                          {/*<Route
                              path="/confirm_by_email"
                              element={<Navigate to={`/shops/${location.search}&action=CONFIRM_EMAIL`} replace/>}
                          />*/}
                        <Route
                            path="*"
                            element={<Navigate to="/not-found" replace/>}
                        />
                      </Routes>
                  </Suspense>
                    <Footer
                        selectedLanguage={locale}
                        onLanguageChange={handleLanguageChange}
                    />
            </BrowserRouter>
        </IntlProvider>
      </div>
  );
}
export default App;
