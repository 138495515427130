import {action} from 'easy-peasy';
import * as Actions from './actions';

const productsModel = {
    products:[],
    loadingProducts:false,
    product:null,
    loadingProduct:false,
    error:null,
    setData:action((state,payload) => {
        state[payload.key] = payload.value;
    }),
    getProducts:Actions.getProducts,
    getProductByUid:Actions.getProductByUid,
};

export default productsModel;
