import {thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";

export const sendMail= thunk(async (actions, payload) => {
    try {
     axiosInstance.post('/custom/contact',{...payload})
         .then(({data})=>{
             actions.setData({key:'messageSent', value:true});
             actions.setData({key:'sendingError',value:null});
         }).catch(err=>{
             console.log('error:', err);
             actions.setData({key:'sendingError',value:err});
             actions.setData({key:'messageSent', value:false});
         })
    }catch (e) {
        console.log(e);
    }
});
