import {thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";
export const getPrioritizedMerchants = thunk(async (actions, payload) => {
    try {
        const {limit} = payload;
        const data = {
            filter: {
                withPriority: true,
            },
            pagination: {
                quantity: limit,
            },
        }
        actions.setData({key:'loading',value:true});
        axiosInstance.post('/merchants/search',data)
            .then(({data})=>{
                actions.setData({key:'merchants', value:data});
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                console.log('error:', err);
                actions.setData({key:'error',value:err});
            })
            .finally(()=>{
                actions.setData({key:'loading',value:false});
            });
    }catch (e) {
        console.log(e);
    }
});
