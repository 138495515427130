import {action} from 'easy-peasy';
import * as Actions from './actions';

const merchantModel = {
    merchant:{},
    error:null,
    loading:false,
    loadingSimilarMerchants:false,
    similarShops:[],
    setData:action((state,payload) => {
            state[payload.key] = payload.value;
    }),
    getMerchant:Actions.getMerchant,
    getSimilarMerchants:Actions.getSimilarMerchants,
};

export default merchantModel;
