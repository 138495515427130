import { createStore } from 'easy-peasy';
import shopModel from "../features/Shops/redux/model";
import merchantModel from "../features/Merchant/redux/model";
import productsModel from "../features/Products/redux/model";
import registerModel from "../features/Register/redux/model";
import contactFormModel from "../features/ContactForm/redux/model";
import technologyModel from "../features/Technology/redux/model";
import userModel  from "../features/User/redux/model";

const store = createStore({
    shop: shopModel,
    merchant: merchantModel,
    products: productsModel,
    register: registerModel,
    contactForm: contactFormModel,
    technology: technologyModel,
    user: userModel,
});

export default store;
