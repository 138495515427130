import {lazy} from "react";

import NavBar from "./NavBar";
const Shop = lazy(() => import('./Shops/index'));
const Merchant = lazy(() => import('./Merchant'));
const AppInfo = lazy(() => import('./AppInfo'));
const Apps = lazy(() => import('./Apps'));
const Register = lazy(() => import('./Register'));
const NotFound = lazy(() => import('./NotFound'));
const Pricing = lazy(() => import('./Pricing'));
const Footer = lazy(() => import('./Footer'));
const ProductPage = lazy(() => import('./ProductPage'));
const ContactForm = lazy(() => import('./ContactForm'));
const Technology = lazy(() => import('./Technology'));
export {NavBar,Shop,Merchant,AppInfo,Apps,Register,NotFound,Pricing, Footer, ProductPage, ContactForm, Technology};
