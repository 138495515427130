import {thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";

export const createTrialAccount = thunk(async (actions, payload) => {
    try {
        const { email, password, storeName } = payload;
        actions.setData({key:'loading',value:true});
        axiosInstance.post('/users/register_by_email_with_merchant_name',{
            email, password, merchantName: storeName
        })
            .then(({data})=>{
            actions.setData({key:'emailSent',value:true});
        })
        .catch(err=>{
            console.log('error:', err);
            actions.setData({key:'errorTrialMessage',value:err});
        })
        .finally(()=>{
                actions.setData({key:'loading',value:false});
        })
    }catch (e) {
        console.log(e);
    }
});

export const setEmailSent = thunk(async (actions, payload) => {
    try {

    }catch (e) {
        console.log(e);
    }
});

export const setErrorTrialMessage = thunk(async (actions, payload) => {
    try {

    }catch (e) {
        console.log(e);
    }
});
