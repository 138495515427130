import React, { useState } from 'react';
import ArSupported from '../../../utils/arSupported';

export default function AndroidSmartBanner() {
  const { isAndroid } = ArSupported();
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClose = () => {
    localStorage.setItem('android_install_banner_closed', true);
    setButtonClicked(true);
  };

  const handleInstall = () => {
    handleClose();
    window.location =
      'https://play.google.com/store/apps/details?id=com.goyuge.merchant&hl=en&gl=US';
  };

  if (
    !isAndroid ||
    localStorage?.getItem('android_install_banner_closed') ||
    buttonClicked
  )
    return <></>;

  return (
    <div className="w-full bg-black flex flex-row items-center h-18 p-2">
      <div className="w-2/12 flex flex-row justify-end">
        <img
          className="w-full"
          src="/img/yuge-merchant-app.png"
          alt="Yuge merchant tool app"
        />
      </div>
      <div className="w-7/12 p-2">
        <h2 className="m-0 text-xl text-gray-50">Yuge Merchant</h2>
        <p className="m-0 text-sm text-gray-50">
          Augmented Reality for Webshops
        </p>
      </div>
      <div className="w-3/12">
        <button
          type="button"
          onClick={handleInstall}
          className="w-full font-bold bg-blue-500 hover:bg-blue-700 text-white py-2 rounded-full">
          OPEN
        </button>
      </div>
    </div>
  );
}
