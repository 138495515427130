import {thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";

export const getBasket = thunk(async (actions, payload) => {
    try {
        actions.setData({key:'loginModalAction',value:true});
        axiosInstance.get('/sales/get-basket')
            .then(({data})=>{
                actions.setData({key:'user', value:data});
                actions.setData({key:'error', value:null});
            })
            .catch(err=>{
                console.log('error:', err);
                actions.setData({key:'error',value:err});
            })
            .finally(()=>{
                actions.setData({key:'loginModalAction',value:false});
            });
    }catch (e) {
        console.log(e);
    }
});
