import {action} from 'easy-peasy';
import * as Actions from './actions'

const technologyModel = {
    loading:false,
    error:null,
    merchants:[],
    setData:action((state,payload) => {
            state[payload.key] = payload.value;
    }),
    getPrioritizedMerchants:Actions.getPrioritizedMerchants,
};

export default technologyModel;
