import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import FreeTrialButton from './freeTrialButton';

const Menu = (props) => {
  const {
    menuItems,
    asListItem,
    onClickItem,
    onClickEvent,
    showContactButton = true,
  } = props;

  const onClickNavLink = (e, item) => {
    if (item.action) {
      e.preventDefault();
    }
    if (typeof onClickItem === 'function') {
      onClickItem(item);
    }
  };

  const onClickItemLI = (e, item) => {
    if (item.action) {
      e.preventDefault();
    }
    if (typeof onClickItem === 'function') {
      onClickItem(item);
    }
  };

  const renderItem = (item, i) => {
    const { href, text, external } = item;
    const link = external ? (
      <a
        className="font-semibold text-gray-500 hover:text-gray-500"
        key={i}
        href={href}
        style={{ whiteSpace: 'nowrap' }}>
        <FormattedMessage id={text} />
      </a>
    ) : (
      <NavLink
        key={i}
        className="font-semibold text-gray-500 hover:text-gray-500"
        activeclassname="-is-active"
        to={href}
        onClick={(e) => onClickNavLink(e, item)}>
        <FormattedMessage id={text} />
      </NavLink>
    );

    return asListItem ? <li key={i}>{link}</li> : link;
  };

  return (
    <>
      {menuItems.map((item, i) => renderItem(item, i))}
      {showContactButton && (
        <FreeTrialButton
          classes="free-trial-account text-white mt-16"
          text={<FormattedMessage id="CONTACT_PAGE" />}
        />
      )}
    </>
  );
};

Menu.propTypes = {
  props: PropTypes.any,
};
export default memo(Menu);
